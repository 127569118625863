import React, { HTMLAttributes, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import countdown from "countdown";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  background: black;
  margin: 0;
  padding: 0;
  color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  margin: 0 auto;

  h1, h2, p {
    margin-top: 0;
  }

  @media screen and (min-width: 720px) {
    font-size: 1.5rem;

    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
    }
  }
`;

const DEPARTURE = new Date("2023-10-12T06:20:00+01:00");
const ARRIVAL = new Date("2023-10-12T19:40:00-06:00");

function useUpdated<T>(fn: () => T, interval: number = 1000) {
  const [value, setValue] = useState(fn());
  useEffect(() => {
    setInterval(() => setValue(fn()), interval);
  }, [fn, interval]);
  return value;
}

const Timespan = styled(function({
  value,
  ...props
}: { value: countdown.Timespan } & Omit<
  HTMLAttributes<HTMLDivElement>,
  "children"
>) {
  const parts = value
    .toString()
    .split(", ")
    .map(x => x.split(" and "))
    .flat();

  return (
    <span {...props}>
      {parts.map((part, key) => (
        <span key={key}>{part}</span>
      ))}
    </span>
  );
})`
  display: block;

  span {
    display: block;
  }

  @media screen and (min-width: 720px) {
    span {
      display: inline;
    }

    span:not(:last-child):not(:first-child):before {
      content: ", ";
    }

    span:last-child:before {
      content: " and ";
    }
  }
`;

export default function CountdownPage() {
  const departure = useUpdated(
    () => countdown(DEPARTURE) as countdown.Timespan
  );
  const arrival = useUpdated(() => countdown(ARRIVAL) as countdown.Timespan);

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Body>
          <h1>Laura {"&"} Yngve go to Mexico</h1>
          <p>🎃👻💀🦇🦇👻💀🎃</p>
          <div>
            <h2>Departure 🛫</h2>
            <p>
              <Timespan value={departure} />
            </p>
          </div>
          <div>
            <h2>Arrival 🛬</h2>
            <p>
              <Timespan value={arrival} />
            </p>
          </div>
        </Body>
      </Wrapper>
    </>
  );
}
